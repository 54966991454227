<template>
  <div class="main">
    <b-row class="mb-5">
      <b-col md="2">
        <b-input-group>
          <date-picker
            placeholder="Từ"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="apiParams.fromDate"
          ></date-picker>
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-input-group>
          <date-picker
            placeholder="Đến"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="apiParams.toDate"
          ></date-picker>
        </b-input-group>
      </b-col>
      <!-- <b-col md="2">
        <b-form>
          <b-form-select
            v-model="apiParams.type"
            :options="BILL_TYPE"
            size="sm"
          ></b-form-select>
        </b-form>
      </b-col> -->
      <b-col md="1">
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="fetchMainData()"
        >Lọc
        </b-button>
      </b-col>
    </b-row>
    <b-table
      :items="mainList.dataset"
      :fields="fields"
      class="table-bordered table-hover col-md-12"
      :busy="onLoadingList"
    >
      <template v-slot:table-busy>
        <vcl-table
          :speed="5"
          :animate="true"
          :columns="6"
        ></vcl-table>
      </template>
      <template v-slot:cell(stt)="row">
        <div
          class="productCode"
          @click="editItem(row.item)"
          style="cursor: pointer; color: #3699ff"
        >
          <span
            v-text="row.item.id"
            class="d-flex justify-content-center"
          ></span>
        </div>
        <i
          v-if="row.item.tradeInType === 1"
          class="fa fa-recycle icon-color"
          v-b-tooltip.hover
          :title="row.item.tradeInTypeName"
        ></i>
        <i
          v-else
          class="fa fa-retweet icon-color"
          v-b-tooltip.hover
          :title="row.item.tradeInTypeName"
        ></i>
      </template>
      <template v-slot:cell(createdAt)="row">
        <p style="margin-bottom: 0; font-weight: 550">
          {{ row.item.createdAt }}
        </p>
        <p style="margin-bottom: 0">
          {{ row.item.createdByName }}
        </p>
      </template>
      <template v-slot:cell(customer)="row">
        <p style="margin-bottom: 0; font-weight: 550">
          {{ row.item.customerName }}
        </p>
        <p style="margin-bottom: 0">
          {{ row.item.customerPhone }}
        </p>
      </template>
      <template v-slot:cell(estimatePrice)="row">
        <div style="text-align: end">
          <span v-text="convertPrice(row.item.estimatePrice)"></span>
        </div>
      </template>
      <template v-slot:cell(finalPrice)="row">
        <div style="text-align: end">
          <span v-text="convertPrice(row.item.finalPrice)"></span>
        </div>
      </template>
      <template v-slot:cell(statusName)="row">
        <span
          v-if="row.item.status === 1"
          v-text="row.item.statusName"
          class="label font-weight-bold label-lg label-light-success label-inline"
        ></span>
        <span
          v-else
          v-text="row.item.statusName"
          class="label font-weight-bold label-lg label-light-info label-inline"
        ></span>
        <p
          v-if="row.item.stockSlipCode"
          v-text="row.item.stockSlipCode"
          style="font-size: 11px; margin-top: 2px"
        ></p>
      </template>
    </b-table>
    <b-row>
      <b-col
        lg="3"
        md="3"
        sm="12"
      >
        <p
          class="mt-3 text-dark"
          style="font-weight: 500"
        >
          Tổng số:
          {{ mainList.total }}
        </p>
      </b-col>
      <b-col
        lg="9"
        md="9"
        sm="12"
      >
        <b-pagination-nav
          class="custom-pagination"
          v-show="mainList.pages >= 2"
          :link-gen="linkGen"
          v-model="currentPage"
          :number-of-pages="mainList.pages"
          use-router
          @change="fetchMainData(pageInfo)"
          align="right"
          first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
          prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
          next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
          last-class="page-item-last btn btn-icon btn-sm my-1 "
          page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
        >
          <template v-slot:first-text>
            <span>
              <i class="ki ki-bold-double-arrow-back icon-xs"></i>
            </span>
          </template>

          <template v-slot:prev-text>
            <i class="ki ki-bold-arrow-back icon-xs"></i>
          </template>

          <template v-slot:next-text>
            <i class="ki ki-bold-arrow-next icon-xs"></i>
          </template>

          <template v-slot:last-text>
            <span class="text-info">
              <i class="ki ki-bold-double-arrow-next icon-xs"></i>
            </span>
          </template>
        </b-pagination-nav>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
.main ::v-deep {
  width: 100%;
  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 6%;
    text-align: center;
  }
  .dateClass {
    width: 10%;
  }
  .customerClass {
    width: 12%;
  }
  .productClass {
    width: 13%;
  }
  .imeiCodeClass {
    width: 13%;
  }
  .estimatePrice {
    width: 10%;
  }
  .finalPrice {
    width: 10%;
  }
  .storeName {
    width: 10%;
  }
  .satusClass {
    width: 10%;
  }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  .productCode:hover {
    text-decoration: underline;
  }
  .icon-color {
    color: #464e5f;
  }
  .test {
    position: absolute;
    right: 1vw;
  }
  .checkbox-label {
    display: block;
  }
  .multiselect {
    min-height: initial;
  }
  .multiselect__tags {
    display: block;
    padding: 3px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 12px;
    min-height: initial;
  }
  .multiselect__element {
    min-height: initial;
    display: block;
    background: #fff;
    font-size: 12px;
  }
  .multiselect__input {
    min-height: initial;
    display: block;
    background: #fff;
    font-size: 12px;
  }
  .multiselect__single {
    font-size: 12px;
  }
  .statusClass {
    text-align: center;
  }
}
</style>

<script>
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from './../../utils/apiUrl';
import _ from 'lodash';
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { formatDate, convertPrice, makeToastFaile } from '../../utils/common';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { BILL_TYPE } from '../../utils/constants';
export default {
  props: ['customerId'],
  components: {
    VclTable,
    datePicker,
  },
  // mixins: [common],
  data() {
    return {
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      fields: [
        {
          key: 'stt',
          label: 'ID',
          sortable: false,
          tdClass: 'sttClass',
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          thClass: 'sttClass',
        },
        {
          key: 'createdAt',
          label: 'Ngày',
          sortable: false,
          tdClass: 'dateClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'dateClass',
        },
        {
          key: 'customer',
          label: 'Khách hàng',
          sortable: false,
          tdClass: 'customerClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'customerClass',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          tdClass: 'productClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'productClass',
        },
        {
          key: 'imeiCode',
          label: 'IMEI',
          sortable: false,
          tdClass: 'imeiCodeClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'imeiCodeClass',
        },
        {
          key: 'estimatePrice',
          label: 'Giá thu dự kiến',
          sortable: false,
          tdClass: 'estimatePrice',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'estimatePrice',
        },
        {
          key: 'finalPrice',
          label: 'Giá thu cuối cùng',
          sortable: false,
          tdClass: 'finalPrice',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'finalPrice',
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          sortable: false,
          tdClass: 'storeName',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'storeName',
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          sortable: false,
          tdClass: 'statusClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'statusClass ',
        },
      ],
      apiParams: {
        page: 1,
        size: 10,
        fromDate: null,
        toDate: null,
        type: null,
      },
      onLoadingList: false,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      currentPage: 1,
      BILL_TYPE,
    };
  },
  mounted() {
    this.fetchMainData();
  },
  methods: {
    formatDate,
    convertPrice,
    fetchMainData: function (params) {
      if (!this.customerId) {
        makeToastFaile('Không tìm thấy Id khách hàng');
        return;
      }
      this.getParamFilters();
      // Init request header.
      this.onLoadingList = true;
      ApiService.query(cmdUrl.TradeInUrl.byCustomer, {
        params: params ? params : { ...this.apiParams },
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch(() => {
          this.onLoadingList = false;
        });
    },
    getParamFilters() {
      this.currentPage = this.$route.query.page;
      if (this.currentPage === undefined) {
        this.currentPage = 1;
      }

      this.apiParams.page = this.currentPage;
      this.apiParams.customerId = this.customerId;
    },
    isShowRow: function (listData, item) {
      return _.findIndex(listData, ['id', item.id]) === 0;
    },
    calculateDiscountPercent: function (bill) {
      const discount = bill.discountAmount || 0;
      const total = bill.totalAmount || 0;
      if (total !== 0) {
        return parseFloat((discount / total) * 100).toFixed(1);
      }
      return 0;
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0,
      );
    },
    linkGen: function (pageNum) {
      return pageNum === 1
        ? `?id=${this.customerId}`
        : `?id=${this.customerId}&page=${pageNum}`;
    },
  },
  computed: {
    sumQty: function () {
      let sum = 0;
      _.map(this.mainList.dataset, (item) => {
        const tmpSum = _.sumBy(item.billItems, 'quantity');
        sum += tmpSum;
      });
      return sum;
    },
  },
};
</script>
